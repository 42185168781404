import { formatKeyValueObject, formatKeyValueArray } from '@/utils/base';

import { StoreBillTypeText } from '@/constant/task';

export const WAVE_COMPOSE_TYPE_ENUM = {
  BATCH: 'BATCH',
  SHIPPING_ORDER: 'SHIPPING_ORDER',
  CUSTOMER: 'CUSTOMER',
};
// 业务类型
export const BIZ_TYPE_ENUM = {
  RECEIPT_ORDER: 'RECEIPT_ORDER', // 入库单
  DELIVER_ORDER: 'DELIVER_ORDER', // 出库单
};
export const SOURCE = [
  {
    label: 'OMS',
    value: 'OMS',
  },
  {
    label: 'WMS',
    value: 'WMS',
  },
];
export const SOURCE_ENUM = SOURCE
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});
export const PICKUP_TYPE = [
  {
    label: '客户自提',
    value: 'CUSTOMER_DELIVERY',
  },
  {
    label: 'TMS配送',
    value: 'TMS_DELIVERY',
  },
];
export const PICKUP_TYPE_ENUM = PICKUP_TYPE
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});
export const URGENT = [
  {
    label: '是',
    value: true,
  },
  {
    label: '否',
    value: false,
  },
];
export const URGENT_ENUM = URGENT
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});
export const RECEIPT_STSTUS = [
  { value: 'PRE_AUDIT', label: '待审核' },
  { value: 'PRE_CHECK_IN', label: '待登记' },
  { value: 'PRE_ACCEPT', label: '待验收' },
  { value: 'ACCEPTING', label: '验收中' },
  { value: 'PRE_STORE', label: '待入库' },
  { value: 'DOING_STORE', label: '入库中' },
  { value: 'PRE_PUT_ON_SHELVES', label: '待上架' },
  { value: 'DOING_PUT_ON_SHELVES', label: '上架中' },
  { value: 'DONE_PUT_ON_SHELVES', label: '上架完成' },
  { value: 'CANCELLED', label: '已取消' },
];
export const RECEIPT_STSTUS_ENUM = RECEIPT_STSTUS
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});
export const RECEIPT_STSTUS_ENUM_VALUE = RECEIPT_STSTUS
  .reduce((total, item) => ({ ...total, [item.value]: item.value }), {});

export const RECEIPT_TYPE = formatKeyValueArray(StoreBillTypeText);
export const RECEIPT_ADD_TYPE = [
  { value: 'DD', label: '订单入库' },
  { value: 'TH', label: '客退入库' },
  { value: 'DB', label: '调拨入库' },
  { value: 'QC', label: '期初建账' },
  { value: 'QT', label: '其它入库' },
  { value: 'JS', label: '拒收入库' },
];
export const RECEIPT_ADD_TYPE_ENUM = RECEIPT_ADD_TYPE
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});
export const RECEIPT_TYPE_TO_RECEIPT_ADD_TYPE = {
  ORDER: 'DD',
  RETURNED: 'TH',
  REJECTED: 'JS',
  TRANSFER: 'DB',
  SETUP: 'QC',
  OTHER: 'QT',
  // REJECTED: 'DB',
  // TRANSFER: 'QC',
  // SETUP: 'QT',
  // OTHER: 'JS',
};

export const TEMPERATURE_LAYER_CODE = [
  { value: '1', label: '常温' },
  { value: '3', label: '冷藏' },
  { value: '2', label: '冷冻' },
  { value: '4', label: '恒温' },
];
export const TEMPERATURE_LAYER_CODE_NEW = [
  { value: 'NORMAL', label: '常温' },
  { value: 'REFRIGERATE', label: '冷藏' },
  { value: 'COLD', label: '冷冻' },
  { value: 'CONSTANT', label: '恒温' },
];
export const TEMPERATURE_LAYER_TO_NEW_ENUM = {
  1: 'NORMAL',
  3: 'REFRIGERATE',
  2: 'COLD',
  4: 'CONSTANT',
};
export const TEMPERATURE_LAYER_CODE_ENUM = TEMPERATURE_LAYER_CODE
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});
export const TEMPERATURE_LAYER_CODE_NEW_ENUM = TEMPERATURE_LAYER_CODE_NEW
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});

export const DOCK_LEGEND_COLOR = ['#fde2e2', '#f9e7cc'];
export const SHIP_STATUS = {
  REGISTERED: DOCK_LEGEND_COLOR[0],
  ACCEPTANCE: DOCK_LEGEND_COLOR[1],
};
export const DOCK_CELL_WIDTH = 160;
export const DOCK_LEGENDS = [
  { text: '已登记', color: DOCK_LEGEND_COLOR[0], borderColor: 'transparent' },
  { text: '验收中', color: DOCK_LEGEND_COLOR[1], borderColor: 'transparent' },
];
export const DOCK_TYPE = [
  { value: 'RECEIPT', label: '收货区' },
  { value: 'DELIVERY', label: '发货区' },
];
export const DOCK_TYPE_ENUM_VALUE = formatKeyValueObject(DOCK_TYPE, { label: 'value' });
