import Moment from '@/utils/moment';

export default {
  timeFormat(time) {
    return Moment.format(time);
  },
};

export const timeFormat = (time) => Moment.format(time);

export const getbeforeTimeByRangeTime = (sourceTime) => {
  if (!sourceTime || !sourceTime[0]) {
    return undefined;
  }

  const time = Moment.toTimestame(sourceTime[0]);

  return Moment.startOf(time);
};
export const getAfterTimeByRangeTime = (sourceTime) => {
  if (!sourceTime || !sourceTime[1]) {
    return undefined;
  }

  const time = Moment.toTimestame(sourceTime[1]);

  return Moment.ensOf(time);
};
export const resetFormRangeTime = (formData, fields) => {
  const judgeFieldIsReset = (
    formDataInside,
    field,
  ) => !(formDataInside[field] && formDataInside[field].length)
      || (formDataInside[field] && formDataInside[field] instanceof Array
      && formDataInside[field].length < 2);
  const newFormData = {};
  fields.forEach((item) => {
    if (judgeFieldIsReset(formData, item)) {
      newFormData[item] = [];
    }
  });
  return newFormData;
};
export const getFileNameByUrl = (url) => {
  const files = url.split('/');
  const fileName = files[files.length - 1] ? files[files.length - 1].split('.')[0] : '';
  return fileName;
};
export const getFileNameByFileUrl = (url = '', suffix = false) => {
  const file = url.split('/');
  const fileExt = file[file.length - 1] ? file[file.length - 1] : '';
  const fileNoExt = fileExt.split('.');
  return !suffix ? fileNoExt[1] : fileExt;
};
