import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 入库信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13272
 */
export function inboundOrderPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/page', data, {
    params,
  });
}
/**
 * @description: 入库信息表 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13274
 */
export function inboundOrderDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/detail', data);
}
/**
 * @description: 入库单取消
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13280
 *
 */
export function inboundOrderCancel(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/cancel', data, {});
}
/**
 * @description: 网关调用（前期前端调试可以直接调用这个接口）
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13270
 *
 */
export function inboundOrderSave(data) {
  return wmsPlusHttp.post('/receipt_order/batch_save', data);
}

/**
 * @description: 货主货品的分页查询-W侧数据
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12438
 *
 */
export function loadGoodsByWms(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_goods/page_by_shipper_v2', data, { params });
}
/**
 * @description: 上传附件
 * @api文档：https://yapi.ops.yunlizhi.cn/project/250/interface/api/5505
 *
 */
export function uploadFile(data) {
  return wmsPlusHttp.post('/bfs_oss/upload', data);
}
/**
 * @description: 上传附件
 * @api文档：https://yapi.ops.yunlizhi.cn/project/250/interface/api/5505
 *
 */
export function downloadFile(data, config) {
  return wmsPlusHttp.download('/warehouse_management_system/file/download', data, config);
}

/**
 * @description: 批属性规则 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11754
 *
 */
export function ruleBatchAttribute(params) {
  return wmsPlusHttp.get('/warehouse_management_system/rule_batch_attribute/get', {}, { params });
}
/**
 * @description: 仓库月台 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11000
 */

export function warehousePlatformPage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/dock/page', data, { params });
}
/**
 * @description: 出库单状态修改
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13944
 */

export function receiptOrderStatusUpdate(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/status/update', data, { params });
}

/**
 * @description: 出库单登记
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14060
 */

export function receiptOrderCheckIn(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/check_in', data, { params });
}

/**
 * @description: 月台登记记录
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14062
 */

export function dockSubscriberList(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/dock/dock_subscriber/list', data, { params });
}

/**
 * @description: 查询入库单对应的规则
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14122
 */

export function receiptOrderRuleDetail(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/rule_detail', data, { params });
}

/**
 * @description: 下载提货单模板
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12508
 *
 */
export function downLoadTemplate(data, fileName) {
  return wmsPlusHttp.download('/warehouse_management_system/file/template/download', data, { fileName, extension: 'xlsx' });
}

/**
 * @description: 文件导入验证
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14372
 *
 */
export function receiptOrderImportCheck(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/import/check', data, { });
}
/**
 * @description: 根据goodsIds查询货品列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11914
 *
 */
export function goodsList(data) {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_goods/list', data);
}
/**
 * @description:导出挂单明细
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16079
 */
export function unfinishedExport(data, file) {
  return wmsPlusHttp.download('/warehouse_management_system/receipt_order/export/unfinished', data, file);
}

/**
 * @description:取消验收
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16093
 */
export function cancelAcceptance(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt/paper_acceptance/cancel', data);
}

/**
 * @description:查询已上传附件
 * @api文档：废弃 https://yapi.ops.yunlizhi.cn/project/382/interface/api/18323
 * https://yapi.ops.yunlizhi.cn/project/382/interface/api/20563
 */
export function fileAttachmentQuery(data) {
  return wmsPlusHttp.post('/warehouse_management_system/file_attachment/v2/query', data);
}

/**
 * @description:新增文件数据
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18305
 */
export function fileAttachmentAdd(data) {
  return wmsPlusHttp.post('/warehouse_management_system/file_attachment/batch/add', data);
}

/**
 * @description:删除单据对应的附件列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18313
 */
export function fileAttachmentDelete(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/attachment_files/delete', data);
}
/**
 * @description:批量删除
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19167
 */
export function fileAttachmentBatchDelete(data) {
  return wmsPlusHttp.post('/warehouse_management_system/file_attachment/batch/delete', data);
}
/**
 * @description: 一键导入
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/21205
 *
 */
export function batchSaveOneClick(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/batch_save_one_click', data);
}
